<template>
  <div
    class="box-check"
    :class="{ selected: checkSelected }"
    @click.prevent="clickCheck"
  >
    <input
      :id="checkId"
      type="checkbox"
      v-model="checkSelected"
      v-show="showCheck"
      @click.self.stop="clickCheck"
    />
    <label :for="checkId">{{ label }}</label>
    <div class="slot-content"><slot></slot></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    showCheck: {
      type: Boolean,
      default: true,
    },
    label: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      checkSelected: this.value,
      checkId: `box-check-${this._uid}`,
    };
  },
  methods: {
    clickCheck() {
      this.checkSelected = !this.checkSelected;
      this.$emit("checked", this.checkSelected);
      this.$emit("input", this.checkSelected);
    },
  },
  watch: {
    value() {
      this.checkSelected = this.value;
    },
  },
};
</script>

<style lang="scss">
.box-check {
  background-color: white;
  width: 100%;
  height: 32px;
  padding: 3px 7px 0 7px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
  &.selected {
    background: $selected-feature-background;
  }

  input {
    position: relative;
    top: 1px;
  }

  label {
    padding-left: 0.25rem;
    color: #333333;
    font-weight: bold;
  }
  .slot-content {
    position: absolute;
    right: 0;
    padding-right: 10px;
    display: inline;
  }
}
</style>
